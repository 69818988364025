import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { font, green } from "./StyleTheme";

export const GleamerToastContainer = styled(ToastContainer).attrs({
  position: "top-center",
  limit: 3,
})`
  .Toastify__toast {
    ${font}
    border-radius: 0.25rem;
    padding: 1rem;
    margin: 0;
  }

  .Toastify__toast--success {
    background: ${green};
  }
`;
