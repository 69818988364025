import * as React from "react";
import styled from "styled-components";
import logo from "../static-assets/boneview_logo_inverse.png";

const LogoImage = styled.img`
  height: 3.5rem;
`;

export function Logo() {
  return (
    <h1>
      <LogoImage src={logo} alt="Gleamer - Boneview" />
    </h1>
  );
}
