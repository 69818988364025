import styled from "styled-components";

type Props = {
  centered?: boolean;
  separated?: boolean;
};

const justifyContent = (props: Props) => {
  if (props.centered) {
    return "center";
  }

  if (props.separated) {
    return "space-between";
  }

  return "flex-start";
};

export const Row = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: ${justifyContent};
`;

export const FlexBlock = styled.div`
  display: flex;
  align-items: center;
`;
