import * as React from "react";
import { EmailText, Header } from "../atoms/Header";
import { Logo } from "../atoms/Logo";
import { NavbarMenu } from "../atoms/Navbar/NavbarMenu";
import { NavbarMenuLink } from "../atoms/Navbar/NavbarMenuLink";
import { ButtonLink } from "../atoms/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Languages } from "../molecules/Language/Languages";

export function LayoutHeader() {
  const { t } = useTranslation();
  return (
    <Header>
      <Link to="/">
        <Logo />
      </Link>
      <div>
        <NavbarMenu>
          <Languages />
          <NavbarMenuLink
            to="/"
            isActive={(match, location) =>
              location.pathname === "/" || /\/study\/.*/.test(location.pathname)
            }
          >
            {t("menu.analyses")}
          </NavbarMenuLink>
          <NavbarMenuLink to="/faq">{t("menu.faq")}</NavbarMenuLink>
          <NavbarMenuLink to="/manual">{t("menu.manual")}</NavbarMenuLink>
          <ButtonLink
            size="large"
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:support@gleamer.ai"
            primary
          >
            {t("menu.support")}
          </ButtonLink>
        </NavbarMenu>
        <EmailText>support@gleamer.ai</EmailText>
      </div>
    </Header>
  );
}
