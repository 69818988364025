import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem;
  height: 3.5rem;
  margin-bottom: 1rem;
`;

export const EmailText = styled.div`
  text-align: right;
  padding-top: 0.5rem;
  padding-right: 0.25rem;
`;
