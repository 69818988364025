import * as React from "react";
import { BaseStyle } from "../atoms/BaseStyle";
import { PageContainer } from "../atoms/PageContainer";
import { LayoutHeader } from "./LayoutHeader";

type Props = {
  children: React.ReactNode;
};

export function Layout({ children }: Props) {
  return (
    <>
      <BaseStyle />
      <PageContainer>
        <LayoutHeader />
        <main>{children}</main>
      </PageContainer>
    </>
  );
}
