import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { red } from "../StyleTheme";

export const NavbarMenuLink = styled(NavLink)`
  font-variant: small-caps;
  font-size: 1.25rem;
  margin: 0 1.25rem;

  &[aria-current="page"] {
    border-bottom: 3px solid ${red};
  }

  &:first-child {
    margin-left: 0;
  }
`;
