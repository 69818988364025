import * as React from "react";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import { HashRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GleamerToastContainer } from "./atoms/GleamerToastContainer";
import { Layout } from "./organisms/Layout";

const StudyList = React.lazy(() => import("./pages/StudyList"));
const Faq = React.lazy(() => import("./pages/Faq"));
const Manual = React.lazy(() => import("./pages/Manual"));

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={{ mode: "dark", font: "default" }}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <HashRouter>
          <Layout>
            <React.Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route path="/" exact component={StudyList} />
                <Route path="/study/:id" component={StudyList} />
                <Route path="/faq" component={Faq} />
                <Route path="/manual" component={Manual} />
              </Switch>
            </React.Suspense>
          </Layout>
        </HashRouter>
        {process.env.NODE_ENV !== "production" && <ReactQueryDevtools />}
        <GleamerToastContainer />
      </ReactQueryCacheProvider>
    </ThemeProvider>
  );
}

export default App;
