import * as React from "react";
import { Row } from "../../atoms/Row";
import { LanguageSelector } from "./LanguageSelector";

export function Languages() {
  return (
    <Row>
      <LanguageSelector languageCode="fr" />
      <LanguageSelector languageCode="en" />
      <LanguageSelector languageCode="it" />
    </Row>
  );
}
