import { createGlobalStyle, css } from "styled-components";
import { activeDark, darkBlue, font, white } from "./StyleTheme";

export const BaseStyle = createGlobalStyle`
  @font-face {
    font-family: 'rennerbook';
    src: url('/fonts/Renner_400_Book.ttf') format('truetype'),
         url('/fonts/renner__400_book.woff2') format('woff2'),
         url('/fonts/renner__400_book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  body {
    ${font};
    background-color: ${darkBlue};
    color: ${white};
  }

  a:visited,
  a:active,
  a:hover,
  a:link {
    color: ${white};
    text-decoration: none;
  }
`;

export const activeBackground = css`
  background: ${activeDark};
`;
