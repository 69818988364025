import { css } from "styled-components";
import theme from "styled-theming";

export const darkBlue = theme("mode", {
  dark: "#20223d",
});

export const white = theme("mode", {
  dark: "#ffffff",
});

export const red = theme("mode", {
  dark: "#ee5858",
});

export const lightRed = theme("mode", {
  dark: "#ea9e9e",
});

export const grey = theme("mode", {
  dark: "#bcbcbc",
});

export const activeDark = theme("mode", {
  dark: "#4c4e63",
});

export const green = theme("mode", {
  dark: "#22BB33",
});

export const font = theme("font", {
  default: css`
    font-family: "rennerbook", Helvetica, Arial, sans-serif;
    font-feature-settings: "pnum" on, "onum" on;
  `,
});
