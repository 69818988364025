import styled, { css } from "styled-components";
import { grey, lightRed, red, white } from "./StyleTheme";

export type ButtonProps = {
  size?: "extra-large" | "large" | "default";
  primary?: boolean;
  secondary?: boolean;
};

const fontSize = (props: ButtonProps) => {
  switch (props.size) {
    case "extra-large":
      return css`
        font-size: 1.5rem;
      `;

    case "large":
      return css`
        font-size: 1.25rem;
      `;

    default:
      return css`
        font-size: 1rem;
      `;
  }
};

const padding = (props: ButtonProps) => {
  if (props.size === "extra-large") {
    return css`
      padding: 1rem 2rem;
    `;
  }

  if (props.size === "large") {
    return css`
      padding: 0.5rem 1rem;
    `;
  }

  return css`
    padding: 0.5rem 1rem;
  `;
};

const mainProperties = (props: ButtonProps) => {
  if (props.primary) {
    return css`
      color: ${white};
      border: 1px solid ${red};
      background-color: ${red};
      font-variant: small-caps;
      margin: 0;
    `;
  }

  if (props.secondary) {
    return css`
      color: ${white};
      border: 1px solid ${lightRed};
      background-color: ${lightRed};
      font-variant: small-caps;
      margin: 0;
    `;
  }

  return css`
    color: ${grey};
    border: 1px solid ${grey};
    background-color: transparent;

    &:hover {
      color: ${white};
      border-color: ${white};
    }
  `;
};

const commonActionCss = css<ButtonProps>`
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${padding}
  ${fontSize}
  ${mainProperties}
`;

export const Button = styled.button<ButtonProps>`
  ${commonActionCss}

  &[disabled] {
    opacity: 0.8;
    cursor: default;
  }

  &:focus {
    text-decoration: underline;
  }
`;

export const ButtonLink = styled.a.attrs(() => ({
  target: "_blank",
  rel: "noopener noreferrer",
}))<ButtonProps>`
  ${commonActionCss}
`;
